<template>
  <div id="app">
    <!-- 考生相关 -->
    <div id="herder" style="display: none">
      <el-row :gutter="15">
        <el-col :span="1">
          <div class="badge_box">
            <span>学科</span>
            <div>
              <span v-if="pageData.subjectType == 0">未知</span>
              <span v-else-if="pageData.subjectType == 1">物理</span>
              <span v-else-if="pageData.subjectType == 2">生物</span>
              <span v-else-if="pageData.subjectType == 3">化学</span>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="badge_box">
            <div>题目</div>
            <div>{{ pageData.aiOperationTestName }}</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="badge_box">
            <div>分值</div>
            <div>{{ pageData.aiScoreFull }}</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="badge_box">
            <div>AI评分</div>
            <div>{{ pageData.aiScore }}</div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!--考试相关-->
    <div id="exam-info-box">
      <el-row style="width: 100%">
        <el-col :span="4" class="exam-info-badge">
          科目：
          <span v-if="pageData.subjectType == 0">未知</span>
          <span v-else-if="pageData.subjectType == 1">物理</span>
          <span v-else-if="pageData.subjectType == 2">生物</span>
          <span v-else-if="pageData.subjectType == 3">化学</span>
        </el-col>
        <el-col :span="12" class="exam-info-badge"
          >题目：{{ pageData.aiOperationTestName }}
        </el-col>
        <el-col :span="3">
          <div class="exam-info-badge">
            <span>分值：</span>
            <span>{{ pageData.aiScoreFull }}</span>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="exam-info-badge">
            <span>AI评分：</span>
            <span>{{ pageData.aiScore }}</span>
          </div>
        </el-col>
        <!--        <el-col :span="6" class="exam-info-badge">-->
        <!--          评分状态：-->
        <!--          <span v-if="markStatus === 1">正在评分</span>-->
        <!--          <span v-if="markStatus === 4">评分结束</span>-->
        <!--        </el-col>-->
      </el-row>
    </div>

    <!-- 播放器  -->
    <el-row :gutter="0" style="width: 100%">
      <div style="height: 532px">
        <el-col :span="12" style="height: 532px">
          <div id="mainVideo"></div>
        </el-col>
        <el-col :span="12" style="height: 532px">
          <div id="subVideo"></div>
        </el-col>
      </div>
    </el-row>

    <!-- 进度条 -->
    <!--    <div id="marking-hand-box">
      <img src="../marking/img/rewind.png" alt @click="customRewind" />
      <img
        v-if="!videoPlay"
        src="../marking/img/play.png"
        alt
        @click="customPlay"
      />
      <img v-else src="../marking/img/pause.png" alt @click="customPause" />
      <img src="../marking/img/speed.png" alt @click="customSpeed" />
      <div
        class="marking-hand-box-progress-bar"
        ref="markingHandBoxProgressBar"
        @click="switchProgress($event)"
      >
        <div
          class="marking-hand-box-progress-bar-point"
          :style="{
            left: Math.floor(progressWidth * videoProgress - 4) + 'px',
          }"
          @click.stop
        ></div>
          <div v-for="(frame, index) of frameList">
              <el-tooltip class="item" effect="dark" :content="frame.displayContent" placement="top-start" :value="index === 0">
                  <div
                       class="marking-hand-box-progress-bar-frame-point"
                       :style="{left: Math.floor(progressWidth * calculateVideoProgress(frame.frameTime) - 4) + 'px',}"
                       @click.stop="jumpPointFrameVideo(frame)"
                  >
                  </div>
              </el-tooltip>
          </div>
      </div>
      <div class="marking-hand-box-progress-time">
        {{ currentDurationTimeString }} / {{ totalDurationTimeString }}
      </div>
      <el-select
        v-model="speedValue"
        style="margin-right: 10px; width: 95px"
        @change="customSpeedChange"
      >
        <el-option label="0.5倍速" :value="0.5"></el-option>
        <el-option label="1倍速" :value="1"></el-option>
        <el-option label="2倍速" :value="2"></el-option>
        <el-option label="4倍速" :value="4"></el-option>
        <el-option label="8倍速" :value="8"></el-option>
      </el-select>
      <el-button
        v-if="pageData.microscope"
        size="medium"
        type="primary"
        @click="microscopeShot"
        >显微镜截图</el-button
      >
      <el-button
        v-if="pageData.examModel == 2 || pageData.examModel == 3"
        size="medium"
        type="primary"
        @click="experReportShot"
        >实验报告截图</el-button
      >
      &lt;!&ndash; <el-button size="medium" type="primary" @click="dowmload">下载</el-button> &ndash;&gt;
    </div>-->

    <div id="marking-hand-box">
      <el-button
        size="medium"
        type="primary"
        @click="dialogTableVisible = true"
        style="margin-right: 40px"
        >查看结果</el-button
      >
      <img src="../marking/img/rewind.png" alt @click="customRewind" />
      <img
        v-if="!videoPlay"
        src="../marking/img/play.png"
        alt
        @click="customPlay"
      />
      <img v-else src="../marking/img/pause.png" alt @click="customPause" />
      <img src="../marking/img/speed.png" alt @click="customSpeed" />
      <div
        class="marking-hand-box-progress-bar-ai"
        ref="markingHandBoxProgressBar"
        @click="switchProgress($event)"
      >
        <div
          v-if="false"
          class="marking-hand-box-progress-bar-point-ai"
          :style="{
            left: Math.floor(progressWidth * videoProgress - 6) + 'px',
          }"
          @click.stop
        ></div>
        <div v-for="(frame, index) of frameList" :key="index">
          <el-tooltip
            class="item"
            effect="dark"
            :content="frame.displayContent"
            placement="top-start"
            :value="firstPointShow && index === 0"
          >
            <div
              class="marking-hand-box-progress-bar-frame-point"
              :style="{
                left:
                  Math.floor(
                    progressWidth * calculateVideoProgress(frame.frameTime) - 4
                  ) + 'px',
              }"
              @click.stop="jumpPointFrameVideo(frame)"
            ></div>
          </el-tooltip>
        </div>
        <div
          class="marking-hand-box-progress-bar-point-right-ai"
          :style="{
            width: Math.floor(progressWidth * (1 - videoProgress)) + 'px',
          }"
        ></div>
        <div
          class="marking-hand-box-progress-bar-frame-point-line-box-ai"
          :style="{ width: Math.floor(progressWidth) + 'px' }"
        ></div>
        <div
          v-for="(operation, index) of operationQuestionList"
          style="z-index: 2"
          :key="operation.operationQuestionId"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="operation.aiOperationItemName"
            placement="bottom-start"
          >
            <div
              class="marking-hand-box-progress-bar-frame-point-line-ai"
              :style="
                index < operationQuestionList.length - 1
                  ? {
                      left:
                        Math.floor(
                          progressWidth *
                            calculateVideoProgress(operation.frameStartTime) -
                            4
                        ) + 'px',
                      width:
                        (Math.floor(
                          progressWidth *
                            calculateVideoProgress(
                              operation.frameEndTime - operation.frameStartTime
                            ) -
                            12
                        ) >= 0
                          ? Math.floor(
                              progressWidth *
                                calculateVideoProgress(
                                  operation.frameEndTime -
                                    operation.frameStartTime
                                ) -
                                12
                            )
                          : 0) + 'px',
                      'z-index': 2,
                    }
                  : {
                      left:
                        Math.floor(
                          progressWidth *
                            calculateVideoProgress(operation.frameStartTime) -
                            4
                        ) + 'px',
                      width:
                        (Math.floor(
                          progressWidth *
                            calculateVideoProgress(
                              totalDuration * 1000 - operation.frameStartTime
                            ) -
                            12
                        ) >= 0
                          ? Math.floor(
                              progressWidth *
                                calculateVideoProgress(
                                  totalDuration * 1000 -
                                    operation.frameStartTime
                                ) -
                                12
                            )
                          : 0) + 'px',
                      'z-index': 2,
                    }
              "
              @click.stop="jumpPointFrameVideo(operation.frameFirst)"
            >
              {{ operation.aiOperationItemName }}
            </div>
          </el-tooltip>
        </div>
        <div
          class="marking-hand-box-progress-bar-frame-point-right-ai"
          :style="{
            width: Math.floor(progressWidth * (1 - videoProgress)) + 'px',
          }"
        ></div>
      </div>
      <div class="marking-hand-box-progress-time">
        {{ currentDurationTimeString }} / {{ totalDurationTimeString }}
      </div>
      <el-select
        v-model="speedValue"
        style="margin-right: 10px; width: 95px"
        @change="customSpeedChange"
      >
        <el-option label="0.5倍速" :value="0.5"></el-option>
        <el-option label="1倍速" :value="1"></el-option>
        <el-option label="2倍速" :value="2"></el-option>
        <el-option label="4倍速" :value="4"></el-option>
        <el-option label="8倍速" :value="8"></el-option>
      </el-select>
      <!-- <el-button size="medium" type="primary" @click="microscopeShot" 0
        >显微镜截图</el-button
      > -->
      <!--  v-if="pageData.examModel == 2 || pageData.examModel == 3" -->
      <!-- <el-button size="medium" type="primary" @click="experReportShot" 1
        >实验报告截图</el-button
      > -->
      <!-- <el-button size="medium" type="primary" @click="dowmload">下载</el-button> -->
      <el-dropdown trigger="hover" placement="bottom" @command="showBigView">
        <el-button type="primary" size="medium"> 考试数据 </el-button>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item :command="0">显微镜截图</el-dropdown-item>
          <el-dropdown-item :command="1">实验报告截图</el-dropdown-item> -->
          <el-dropdown-item :command="0">显微镜截图</el-dropdown-item>
          <el-dropdown-item :command="1">试卷拍照</el-dropdown-item>
          <el-dropdown-item :command="2">电子试卷</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 表格 -->
    <el-row :gutter="5" id="scoreDetailsTable" v-loading="loading">
      <el-col :span="12">
        <el-table
          stripe
          border
          ref="leftMarkingVideoTable"
          :data="leftOperationQuestionList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          row-key="aiOperationItemNum"
        >
          <el-table-column
            align="center"
            prop="index"
            label="序号"
            show-overflow-tooltip
            :width="56"
          ></el-table-column>
          <el-table-column align="left" label="评分项" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.aiOperationItemContent }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="aiScoreFull"
            label="分值"
            show-overflow-tooltip
            :width="65"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="aiScore"
            label="AI评分"
            show-overflow-tooltip
            :width="65"
          ></el-table-column>
          <el-table-column
            align="center"
            label="评分依据"
            show-overflow-tooltip
            :width="120"
            v-once
          >
            <template slot-scope="scope">
              <div
                v-if="renderPointFrame(scope.row).length > 0"
                class="pointFrameImg"
                style="height: 54px"
                @click="jumpPointFrameListVideo(renderPointFrame(scope.row))"
              >
                <img :src="renderPointFrameImg(renderPointFrame(scope.row))" />
              </div>
              <div v-else style="height: 54px"></div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>

      <el-col :span="12">
        <el-table
          stripe
          border
          ref="rightMarkingVideoTable"
          :data="rightOperationQuestionList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          row-key="aiOperationItemNum"
        >
          <el-table-column
            prop="index"
            label="序号"
            show-overflow-tooltip
            :width="56"
            align="center"
          ></el-table-column>
          <el-table-column align="left" label="评分项" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.aiOperationItemContent }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="aiScoreFull"
            label="分值"
            show-overflow-tooltip
            :width="65"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="aiScore"
            label="AI评分"
            show-overflow-tooltip
            :width="65"
          ></el-table-column>
          <el-table-column
            align="center"
            label="评分依据"
            show-overflow-tooltip
            :width="120"
            v-once
          >
            <template slot-scope="scope">
              <div
                v-if="renderPointFrame(scope.row).length > 0"
                class="pointFrameImg"
                style="height: 54px"
                @click="jumpPointFrameListVideo(renderPointFrame(scope.row))"
              >
                <img :src="renderPointFrameImg(renderPointFrame(scope.row))" />
              </div>
              <div v-else style="height: 54px"></div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <div id="exit_btn" style="text-align: center; margin-top: 20px">
      <el-button type="primary" size="medium" @click="exit">退出</el-button>
    </div>

    <!-- 查看大图 -->
    <el-row>
      <!-- <el-image-viewer
        v-if="showFace"
        :on-close="closeViewer"
        :url-list="faceList"
      ></el-image-viewer> -->
      <pictureView
        v-if="showFace"
        :on-close="closeViewer"
        :url-list="faceList"
      ></pictureView>
    </el-row>
    <!-- 查看结果 -->
    <el-dialog
      width="1080px"
      title="查看结果"
      :visible.sync="dialogTableVisible"
    >
      <el-table
        :data="dialogTable"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        stripe
        border
        height="600px"
        highlight-current-row
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="56"
        ></el-table-column>

        <el-table-column align="center" type="expand" label="详情" width="56">
          <template slot-scope="scoped">
            <el-table
              :data="scoped.row.aiResultItemPointDTOList"
              :header-cell-style="{
                'text-align': 'center',
                backgroundColor: '#dfe6ec',
              }"
              border
            >
              <el-table-column label="" width="111"></el-table-column>
              <el-table-column
                align="center"
                show-overflow-tooltip
                property="aiPointCode"
                label="得分点ID"
                width="111"
              ></el-table-column>
              <el-table-column
                align="center"
                property="aiScoreFull"
                label="得分点"
                width="110"
              >
                <template slot-scope="scoped2">
                  <span v-if="!scoped2.row.aiPointType" style="color: #1890ff"
                    >操作得分点</span
                  >
                  <span v-else style="color: #67c23a">报告得分点</span>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                property="aiPointContent"
                label="评分项"
              ></el-table-column>

              <el-table-column
                property="aiScoreFull"
                label="总分"
                width="56"
                align="center"
              ></el-table-column>
              <el-table-column
                property="aiScore"
                label="得分"
                width="55"
                align="center"
              >
                <template slot-scope="color">
                  <span
                    :style="
                      color.row.aiScoreFull > color.row.aiScore
                        ? 'color: #E6A23C'
                        : ''
                    "
                    >{{ color.row.aiScore }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          property="aiOperationItemCode"
          label="ID"
          width="110"
          align="center"
        ></el-table-column>
        <el-table-column
          property="aiQuestionItemType"
          label="步骤项"
          width="110"
          align="center"
        >
          <template slot-scope="scoped">
            <span
              v-if="scoped.row.aiQuestionItemType === 0"
              style="color: #1890ff"
              >操作步骤</span
            >
            <span
              v-else-if="scoped.row.aiQuestionItemType === 1"
              style="color: #67c23a"
              >报告步骤</span
            >
            <span v-else>混合步骤</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          property="aiOperationItemName"
          label="评分项"
        ></el-table-column>
        <el-table-column
          width="56"
          property="aiScoreFull"
          label="总分"
          align="center"
        ></el-table-column>
        <el-table-column
          width="56"
          property="aiScore"
          label="得分"
          align="center"
        >
          <template slot-scope="color">
            <span
              :style="
                color.row.aiScoreFull > color.row.aiScore
                  ? 'color: #E6A23C'
                  : ''
              "
              >{{ color.row.aiScore }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import Player, { Events } from 'xgplayer'
import 'xgplayer/dist/index.min.css'
import { isEmpty, durationToTimeSting } from '@/utils/util'
import { getAiMarkingApi, getAiMarkingVideoApi } from '@/api/marking/ai.js'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import pictureView from "@/components/pictureView";
export default {
  name: 'detailScoring',
  components: {
    ElImageViewer,
    pictureView
  },
  data() {
    return {
      loading: false,
      //播放器相关
      mainPlayer: null,
      subPlayer: null,
      mainStreamUrl: '',
      subStreamUrl: '',
      //控制暂停
      videoPlay: true,
      totalDuration: 0,
      currentDuration: 0,
      timeSetInterval: NaN,
      progressWidth: 0,
      speedValue: 1,
      //路由参数
      urlParam: {
        experimentUuid: '',
      },
      pageData: {},
      videoData: {},
      frameList: [],
      //表格数据
      operationQuestionList: [],
      leftOperationQuestionList: [],
      leftOperationQuestionPointEventList: [],
      rightOperationQuestionList: [],
      rightOperationQuestionPointEventList: [],
      //大图展示
      showFace: false,
      faceList: [],
      //考生视频异常相关
      errorDialog: false,
      errorState: false,
      examineeErrorInfo: {},
      examineeErrorDes: '',
      firstPointShow: false,
      // 新实验报告
      reportAddr: [],
      // 查看结果
      dialogTable: [],
      dialogTableVisible: false,
    }
  },
  computed: {
    totalDurationTimeString() {
      return durationToTimeSting(this.totalDuration)
    },
    currentDurationTimeString() {
      return durationToTimeSting(this.currentDuration)
    },
    videoProgress() {
      return (this.currentDuration / this.totalDuration).toFixed(2)
        ? (this.currentDuration / this.totalDuration).toFixed(2)
        : 0
    },
  },
  beforeDestroy() {
    this.destroyPlayer()
  },
  mounted() {
    this.renderUrlParam()
    setTimeout(() => {
      this.initPlayer(this.mainStreamUrl, this.subStreamUrl)
    }, 1000)
    this.$nextTick(() => {
      this.progressWidth = Math.floor(
        this.$refs.markingHandBoxProgressBar.clientWidth
      )
    })
  },
  methods: {
    //异常上报弹窗
    errorReport() {
      this.errorDialog = true
    },
    calculateVideoProgress(duration) {
      if (duration < 0) {
        duration = 0
      }
      return ((duration / 1000).toFixed(2) / this.totalDuration).toFixed(2)
        ? ((duration / 1000).toFixed(2) / this.totalDuration).toFixed(2)
        : 0
    },
    //获取考生视频数据
    getExamineeVideo(experimentResultCode) {
      getAiMarkingVideoApi(experimentResultCode).then((res) => {
        this.videoData = {}
        if (res.success) {
          console.log('获取考生视频数据', res.data)
          this.microscopePictureAddr = res.data.microscopePictureAddr
          this.reportAddr = res.data.reportAddr
          this.videoData = res.data
          // 流地址处理
          this.mainStreamUrl = this.$imageAddress(
            this.videoData.topCameraStream0
          )
          if (!isEmpty(this.videoData.frontCameraStream0)) {
            this.subStreamUrl = this.$imageAddress(
              this.videoData.frontCameraStream0
            )
          } else if (!isEmpty(this.videoData.sideCameraStream0)) {
            this.subStreamUrl = this.$imageAddress(
              this.videoData.sideCameraStream0
            )
          } else {
            this.subStreamUrl = this.$imageAddress(
              this.videoData.frontCameraStream0
            )
          }
          this.startPlayer(this.mainStreamUrl, this.subStreamUrl)
        } else {
          if (res.code === 504) {
            this.msgWarning('视频上传中，请稍后')
          } else if (res.code !== 500) {
            this.msgWarning(res.code)
          }
        }
      })
    },
    closed() {
      this.errorDialog = false
      this.examineeErrorDes = ''
    },
    renderUrlParam() {
      this.urlParam.experimentUuid = ''
      this.urlParam.experimentUuid = this.$route.query.experimentUuid
      this.renderPageData()
    },
    renderPageData() {
      if (isEmpty(this.urlParam.experimentUuid)) {
        return
      }
      this.getAchievement()
    },
    //查看成绩明细
    getAchievement() {
      this.loading = true
      getAiMarkingApi(this.urlParam.experimentUuid)
        .then((res) => {
          if (res.success) {
            this.pageData = {}
            this.pageData = res.data
            this.dialogTable = JSON.parse(
              JSON.stringify(res.data.aiResultOperationQuestionItemDTOList)
            )
            console.log('查看成绩明细', this.pageData)
            this.getExamineeVideo(this.urlParam.experimentUuid)
            //table数据处理
            this.operationQuestionList = []
            this.operationQuestionList =
              this.pageData.aiResultOperationQuestionItemDTOList
            this.leftOperationQuestionList = []
            this.rightOperationQuestionList = []
            let listSize = this.operationQuestionList.length
            let splitPoint = Math.ceil(listSize / 2)
            this.operationQuestionList.forEach((item, index) => {
              item.index = index + 1
              if (index < splitPoint) {
                this.leftOperationQuestionList.push(item)
              } else {
                this.rightOperationQuestionList.push(item)
              }
              let currentFrameList = this.renderPointFrame(item)
              if (currentFrameList.length > 0) {
                this.frameList = this.frameList.concat(currentFrameList)
              }
            })
            // 排序
            this.frameList.sort(this.frameListSort)
            let nextFrameStart = 0
            for (let i = this.operationQuestionList.length - 1; i >= 0; i--) {
              let operationQuestion = this.operationQuestionList[i]
              let currentFrameList = this.renderPointFrame(operationQuestion)
              if (currentFrameList.length > 0) {
                currentFrameList.sort(this.frameListSort)
                let firstFrame = currentFrameList[0]
                if (firstFrame.frameTime) {
                  operationQuestion.frameStartTime = firstFrame.frameTime
                  operationQuestion.frameEndTime = nextFrameStart
                  operationQuestion.frameFirst = firstFrame
                  nextFrameStart = operationQuestion.frameStartTime
                }
              }
            }
            this.loading = false
          } else {
            if (res.code != '500') {
              this.msgWarning(res.msg)
            } else {
              this.msgError('获取成绩明细信息失败')
            }
          }
        })
        .catch((e) => {
          this.loading = false
        })
    },
    initPlayer: function (mainStreamUrl, subStreamUrl) {
      this.$nextTick(() => {
        //主视频加载
        this.mainPlayer = new Player({
          id: 'mainVideo',
          url: mainStreamUrl,
          //初始化显示视频首帧
          videoInit: true,
          //音量(自动播放需配合静音)
          volume: 0,
          //自动播放
          autoplay: true,
          //循环播放
          // loop: true,
          //关闭控制条
          controls: false,
          closeVideoClick: true,
          loop: true,
          width: '100%',
          height: '100%',
          fluid: false,
          // fitVideoSize: 'fixHeight',
        })
        //辅视频加载
        this.subPlayer = new Player({
          id: 'subVideo',
          url: subStreamUrl,
          //初始化显示视频首帧
          videoInit: true,
          //音量(自动播放需配合静音)
          volume: 0,
          //自动播放
          autoplay: true,
          //循环播放
          // loop: true,
          //关闭控制条
          controls: false,
          closeVideoClick: true,
          loop: true,
          width: '100%',
          height: '100%',
          fluid: false,
          // fitVideoSize: 'fixHeight',
        })
        this.mainPlayer.once('play', () => {
          this.currentDuration = this.mainPlayer.currentTime
            ? this.mainPlayer.currentTime
            : 0
          this.speedValue = 1
          this.customSpeedChange(this.speedValue)
          this.timeSetInterval = setInterval(() => {
            // 进度条定时器
            this.currentDuration = this.mainPlayer.currentTime
              ? this.mainPlayer.currentTime
              : 0
            // this.currentDuration = this.currentDuration + 1
            if (this.currentDuration >= this.totalDuration) {
              this.mainPlayer.replay()
              this.subPlayer.replay()
            }
          }, 200)
          if (this.totalDuration === 0) {
            this.totalDuration = this.mainPlayer.duration
              ? this.mainPlayer.duration
              : 0
            this.firstPointShow = true
            return
          }
          if (
            this.totalDuration !== 0 &&
            this.totalDuration > this.mainPlayer.duration
          ) {
            this.totalDuration = this.mainPlayer.duration
              ? this.mainPlayer.duration
              : 0
          }
          this.firstPointShow = true
          // for (let i = 0; i < this.frameList.length; i++) {
          //     let frame = this.frameList[i];
          //     if (frame.frameTime) {
          //         if (this.totalDuration !==0 && frame.frameTime > this.totalDuration) {
          //             frame.frameTime = this.totalDuration
          //             console.log('frame.frameTime', frame.frameTime)
          //         }
          //     }
          // }
        })
        this.subPlayer.once('play', () => {
          if (this.totalDuration === 0) {
            this.totalDuration = this.subPlayer.duration
              ? this.subPlayer.duration
              : 0
            this.firstPointShow = true
            return
          }
          if (
            this.totalDuration !== 0 &&
            this.totalDuration > this.subPlayer.duration
          ) {
            this.totalDuration = this.subPlayer.duration
              ? this.subPlayer.duration
              : 0
          }
          this.firstPointShow = true
        })
        this.mainPlayer.once('destroy', () => {
          clearInterval(this.timeSetInterval)
        })
      })
    },
    startPlayer(mainStreamUrl, subStreamUrl) {
      this.mainPlayer.start(mainStreamUrl)
      this.subPlayer.start(subStreamUrl)

      this.customSpeedChange(this.speedValue)
      this.videoPlay = true
    },
    destroyPlayer() {
      this.mainPlayer.destroy(false)
      this.subPlayer.destroy(false)
    },
    //快退5s
    customRewind() {
      if (this.currentDuration < 5) {
        this.mainPlayer.currentTime = 0
        this.subPlayer.currentTime = 0
        return
      }
      this.mainPlayer.currentTime = this.currentDuration - 5
      this.subPlayer.currentTime = this.currentDuration - 5
    },
    //播放
    customPlay() {
      console.log('play')
      this.videoPlay = true
      this.mainPlayer.play()
      this.subPlayer.play()
    },
    customSwitchProgress() {
      this.mainPlayer.currentTime = this.currentDuration
      this.subPlayer.currentTime = this.currentDuration
    },
    //暂停
    customPause() {
      console.log('paused')
      this.videoPlay = false
      this.mainPlayer.pause()
      this.subPlayer.pause()
    },
    //快进5s
    customSpeed() {
      if (this.currentDuration + 5 > this.totalDuration) {
        return
      }
      this.mainPlayer.currentTime = this.currentDuration + 5
      this.subPlayer.currentTime = this.currentDuration + 5
    },
    //播放倍数改变
    customSpeedChange(num) {
      this.mainPlayer.playbackRate = num
      this.subPlayer.playbackRate = num
    },
    switchProgress(e) {
      this.currentDuration = Math.floor(
        (e.offsetX / this.progressWidth) * this.totalDuration
      )
      this.customSwitchProgress()
    },
    microscopeShot() {
      this.showBigView(0)
    },
    experReportShot() {
      this.showBigView(1)
    },
    // showBigView(type) {
    //   switch (type) {
    //     case 0:
    //       // if (this.pageData.microscopePictureAddr.length <= 0) {
    //       //   this.msgWarning('未上传显微镜截图')
    //       //   return
    //       // }
    //       // this.handleResultImg(this.pageData.microscopePictureAddr)
    //       if (
    //         !this.microscopePictureAddr ||
    //         !this.microscopePictureAddr.length
    //       ) {
    //         this.msgWarning('未上传显微镜截图')
    //         return
    //       }
    //       this.handleResultImg(this.microscopePictureAddr)
    //       break
    //     case 1:
    //       // if (this.pageData.reportAddr.length <= 0) {
    //       //   this.msgWarning('未上传实验报告截图')
    //       //   return
    //       // }
    //       // this.handleResultImg(this.pageData.reportAddr)
    //       if (!this.reportAddr || !this.reportAddr.length) {
    //         this.msgWarning('未上传实验报告截图')
    //         return
    //       }
    //       this.handleResultImg(this.reportAddr)
    //       break
    //   }
    //   this.showFace = true
    // },
    showBigView(type) {
      switch (type) {
        case 0:
          this.checkAndPush(
            this.videoData.microscopePictureAddr,
            '未上传显微镜截图'
          )
          break
        case 1:
          this.checkAndPush(this.videoData.answerHtmlImgAddr, '未上传试卷拍照')
          break
        case 2:
          this.checkAndPush(this.videoData.reportAddr, '未上传电子试卷')
          break
      }
    },
    checkAndPush(addr, warningMsg) {
      if (addr == null || addr.length <= 0) {
        this.$message.warning(warningMsg)
        return
      }
      this.faceList = addr.map(this.$imageAddress)
      this.showFace = true
      // this.addPictureFlipBtn()
    },
    // 添加图片翻转按钮,优化按钮样式
    addPictureFlipBtn() {
      this.$nextTick(() => {
        const wrapper = document.querySelector(
          '.el-image-viewer__actions__inner'
        )
        const flipImg = document.createElement('div')
        flipImg.classList.add('flip-picture')
        flipImg.addEventListener('click', () => {
          const img = document.querySelector(
            '.el-image-viewer__canvas .el-image-viewer__img'
          )
          img.style.transform = img.style.transform + 'scaleX(-1)'
        })
        wrapper.insertBefore(flipImg, wrapper.children[5])
        // 优化样式
        const closeImageBtn = document.querySelector('.el-image-viewer__close')
        const prevImageBtn = document.querySelector('.el-image-viewer__prev')
        const nextImageBtn = document.querySelector('.el-image-viewer__next')
        closeImageBtn && (closeImageBtn.style.backgroundColor = 'rgb(47,47,47)')
        prevImageBtn && (prevImageBtn.style.backgroundColor = 'rgb(47,47,47)')
        nextImageBtn && (nextImageBtn.style.backgroundColor = 'rgb(47,47,47)')
      })
    },
    handleResultImg(listReturn) {
      if (listReturn) {
        let imgResult = []
        listReturn.forEach((item) => {
          if (!isEmpty(item)) {
            imgResult.push(this.$imageAddress(item))
          }
        })
        this.faceList = imgResult
      }
    },
    jumpPointFrameListVideo(aiResultOperationQuestionItemDTOList) {
      if (aiResultOperationQuestionItemDTOList.length > 0) {
        this.currentDuration = Math.floor(
          aiResultOperationQuestionItemDTOList[0].frameTime / 1000
        )
        this.customSwitchProgress()
      }
    },
    jumpPointFrameVideo(aiResultOperationQuestionItemDTO) {
      this.currentDuration = (
        aiResultOperationQuestionItemDTO.frameTime / 1000
      ).toFixed(2)
      this.customSwitchProgress()
    },
    renderPointFrame(aiResultOperationQuestionItemDTO) {
      // 目前只展示当前评分项的第一个
      let list = aiResultOperationQuestionItemDTO.aiResultItemPointDTOList
      if (list && list.length > 0) {
        // for (let index = 0; index < list.length; index++) {
        //   let eventList = list[index].aiResultItemPointEventDTOList
        //   if (eventList && eventList.length > 0) {
        //     let aiEventList = eventList[0].aiResultItemPointEventAiEventDTOList
        //     if (aiEventList && aiEventList.length > 0) {
        //       let frameList =
        //         aiEventList[0].aiResultItemPointEventAiEventFrameDTOList
        //       if (frameList && frameList.length > 0) {
        //         frameList.forEach((item) => {
        //           item.displayContent = list[0].aiPointContent
        //         })
        //         return frameList
        //       }
        //     }
        //   }
        // }
        for (let index = 0; index < list.length; index++) {
          let eventList = list[index]?.aiResultItemPointEventDTOList
          let aiEventList = eventList?.[0]?.aiResultItemPointEventAiEventDTOList
          let frameList =
            aiEventList?.[0]?.aiResultItemPointEventAiEventFrameDTOList
          if (frameList && frameList.length > 0) {
            frameList.forEach((item) => {
              item.displayContent = list[0].aiPointContent
            })
            return frameList
          }
        }
      }
      return []
    },
    renderPointFrameImg(aiResultItemPointEventAiEventFrameDTOList) {
      if (aiResultItemPointEventAiEventFrameDTOList.length > 0) {
        return this.$imageAddress(
          aiResultItemPointEventAiEventFrameDTOList[0].frameUrl
        )
      } else {
        return ''
      }
    },
    exit() {
      this.$router.push({
        path: '/scoreAI/scoring',
      })
    },
    closeViewer() {
      this.faceList = []
      this.showFace = false
    },
    frameListSort(frameFirst, frameSecond) {
      return Math.sign(frameFirst.frameTime - frameSecond.frameTime)

      // if (frameFirst.frameTime - frameSecond.frameTime < 0) {
      //   return -1
      // } else if (frameFirst.frameTime - frameSecond.frameTime > 0) {
      //   return 1
      // } else {
      //   return 0
      // }
    },
  },
}
</script>
<style lang="scss" scoped>
#app {
  padding: 15px;
}

#herder {
  background-color: rgb(245, 246, 250);
  padding: 12px;
}

#herder .badge_box {
  background: #ffffff;
  border-radius: 3px;
  padding: 10px 15px;
}

#herder .badge_box > div:first-child {
  font-size: 17px;
  color: #6b6a6a;
}

#herder .badge_box > div:last-child {
  margin-top: 2px;
  font-size: 18px;
  color: #1890ff;
  word-wrap: break-word;
}

#exam-info-box {
  margin-top: 5px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #1890ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.exam-info-badge {
  font: normal bolder 16px 'Source Han Sans CN-Medium, Source Han Sans CN';
  color: #fff;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 17px;
}

#marking-hand-box {
  width: 100%;
  height: 55px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 201;
}

#marking-hand-box > img {
  margin-right: 10px;
  cursor: pointer;
}

.marking-hand-box-progress-bar {
  width: 580px;
  height: 10px;
  line-height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  // margin-bottom: 18px;
  //border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  z-index: 0;
  background: #a9d5fd;
  cursor: pointer;
}

.marking-hand-box-progress-bar-frame-point {
  width: 8px;
  height: 8px;
  border: 2px solid #4e67e8;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  z-index: 3;
  top: -1px;
  background: #ffffff;
  display: inline-block;
  left: 0;
  cursor: pointer;
}

.marking-hand-box-progress-bar-frame-point-line-box {
  height: 18px;
  border-bottom-right-radius: 10px;
  line-height: 18px;
  position: absolute;
  background: #a9d5fd;
  border-bottom-left-radius: 8px;
  z-index: 0;
  top: 10px;
  display: inline-block;
  left: 0;
  cursor: pointer;
  overflow: hidden; //超出隐藏
  white-space: nowrap; //不换行，同一行展示
}

.marking-hand-box-progress-bar-frame-point-line {
  height: 18px;
  width: 100%;
  //border-left: 0px solid #efefef;
  line-height: 18px;
  position: absolute;
  z-index: 1;
  top: 10px;
  background: rgb(239, 239, 239, 0);
  display: inline-block;
  left: 0;
  cursor: pointer;
  font-size: 14px;
  overflow: hidden; //超出隐藏
  white-space: nowrap; //不换行，同一行展示
}

.marking-hand-box-progress-bar-point {
  width: 8px;
  height: 8px;
  border: 2px solid #1890ff;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  z-index: 2;
  top: -1px;
  background: #ffffff;
  display: inline-block;
  left: 0;
  cursor: pointer;
}

.marking-hand-box-progress-bar-point-right {
  position: absolute;
  height: 10px;
  //border-radius: 10px;
  border-top-right-radius: 8px;
  z-index: 1;
  background: #ffffff;
  display: inline-block;
  right: 0;
  pointer-events: none;
}

.marking-hand-box-progress-bar-frame-point-right {
  position: absolute;
  top: 10px;
  height: 18px;
  //border-radius: 0px;
  border-bottom-right-radius: 8px;
  z-index: 1;
  background: rgb(255, 255, 255);
  display: inline-block;
  right: 0;
  pointer-events: none;
}

.marking-hand-box-progress-time {
  width: 150px;
  margin-right: 25px;
}

.pointFrameImg {
  width: 100%;
  height: 100%;
  border: 1px solid #cbd8f5;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

::v-deep .xgplayer-start {
  display: none !important;
}

::v-deep .xgplayer-error {
  display: none !important;
}

#scoreDetailsTable {
  margin-top: 5px;
}

/* #scoreDetailsTable ::v-deep .el-table .el-table__cell {
  padding: 6px 0;
} */

// #exit_btn {
//   margin-top: 20px;
//   text-align: center;
// }

//::v-deep video {
//  object-fit: cover;
//}

.marking-hand-box-progress-bar-ai {
  width: 580px;
  height: 10px;
  line-height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 18px;
  //border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  z-index: 0;
  background: #a9d5fd;
  cursor: pointer;
}

.marking-hand-box-progress-bar-frame-point-ai {
  width: 8px;
  height: 8px;
  border: 2px solid #4e67e8;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  z-index: 3;
  top: -1px;
  background: #ffffff;
  display: inline-block;
  left: 0;
  cursor: pointer;
}

.marking-hand-box-progress-bar-frame-point-line-box-ai {
  height: 18px;
  border-bottom-right-radius: 10px;
  line-height: 18px;
  position: absolute;
  background: #a9d5fd;
  border-bottom-left-radius: 8px;
  z-index: 0;
  top: 10px;
  display: inline-block;
  left: 0;
  cursor: pointer;
  overflow: hidden; //超出隐藏
  white-space: nowrap; //不换行，同一行展示
}

.marking-hand-box-progress-bar-frame-point-line-ai {
  height: 18px;
  width: 100%;
  color: #4e67e8;
  //border-left: 0px solid #efefef;
  line-height: 18px;
  position: absolute;
  z-index: 1;
  top: 10px;
  background: rgb(239, 239, 239, 0);
  display: inline-block;
  left: 0;
  cursor: pointer;
  font-size: 14px;
  overflow: hidden; //超出隐藏
  white-space: nowrap; //不换行，同一行展示
}

.marking-hand-box-progress-bar-point-ai {
  width: 8px;
  height: 8px;
  border: 2px solid #1890ff;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  z-index: 2;
  top: -1px;
  background: #ffffff;
  display: inline-block;
  left: 0;
  cursor: pointer;
}

.marking-hand-box-progress-bar-point-right-ai {
  position: absolute;
  height: 10px;
  //border-radius: 10px;
  border-top-right-radius: 8px;
  z-index: 1;
  background: #ffffff;
  display: inline-block;
  right: 0;
  pointer-events: none;
}

.marking-hand-box-progress-bar-frame-point-right-ai {
  position: absolute;
  top: 10px;
  height: 18px;
  //border-radius: 0px;
  border-bottom-right-radius: 8px;
  z-index: 1;
  background: rgb(255, 255, 255);
  display: inline-block;
  right: 0;
  pointer-events: none;
}

::v-deep .el-table__expanded-cell {
  padding: 0;
}
</style>
