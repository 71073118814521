import { request, noTimeOutReq } from '@/utils/request.js'

//获取考生视频数据
export function getAiMarkApi (examId, examineeId) {
  return request({
    url: `/exam/score/ai/mark/${examId}/${examineeId}/get`,
    method: 'post',
  })
}

//获取考生阅卷记录
// export function getAiExamineeMarkApi (examId, examineeId) {
//   return request({
//     url: `/ai/result/operation/question/get/examinee/${examId}/${examineeId}`,
//     method: 'post',
//   })
// }
export function getAiExamineeMarkApi (examId, examineeId) {
  return request({
    url: `/ai/result/examinee/mark/get/examinee/${examineeId}`,
    method: 'post',
  })
}


//全部展示 AI阅卷 正在评分
export function getAiMarkingListApi (formData) {
  return request({
    url: `/ai/result/operation/question/marking/list`,
    method: 'post',
    data: formData
  })
}

//获取 展示 AI阅卷 正在评分详情
export function getAiMarkingApi (experimentUuid) {
  return request({
    url: `/ai/result/operation/question/marking/get/code`,
    method: 'post',
    data: `experimentUuid=${experimentUuid}`
  })
}

//获取 展示 AI阅卷 正在评分详情
export function getAiMarkingVideoApi (experimentResultCode) {
  return request({
    url: `/ai/result/operation/question/get/video/experiment/result/code`,
    method: 'post',
    data: `experimentResultCode=${experimentResultCode}`
  })
}

//全部展示 AI阅卷 正在评分
export function getPageAiMarkRecordListApi (formData, pageIndex, pageSize) {
  return request({
    url: `/ai/result/operation/question/page/${pageIndex}/${pageSize}/list`,
    method: 'post',
    data: formData
  })
}


//获取 展示 AI阅卷 正在评分详情
// export function getExamineeAiMarkingApi (examId, examineeId) {
//   return request({
//     url: `/ai/result/operation/question/get/examinee/${examId}/${examineeId}`,
//     method: 'post',
//   })
// }
export function getExamineeAiMarkingApi (examId, examineeId) {
  return request({
    url: `/ai/result/examinee/mark/get/examinee/${examineeId}`,
    method: 'post',
  })
}

//获取AI阅卷记录详情
export function getResultQuestion (aiResultPractiseClassRecordId) {
  return request({
    url: `/ai/result/class/operation/question/get/${aiResultPractiseClassRecordId}`,
    method: 'post',
  })
}


//修改Ai阅卷置信,confidence--1代表置为可信，0代表不可信
export function updateResultQuestion (data) {
  return request({
    url: `/ai/result/class/operation/question/update/confidence`,
    method: 'post',
    data
  })
}

//修改Ai阅卷置信,confidence--1代表置为可信，0代表不可信
export function updateOperationQuestion (data) {
  return request({
    url: `/ai/result/operation/question/update/confidence`,
    method: 'post',
    data
  })
}


//分页获取考生Ai阅卷列表
export function getAIExamineeMarkList (pageIndex, pageSize, data) {
  return request({
    url: `/search/ai/examinee/mark/page/${pageIndex}/${pageSize}/list`,
    method: 'post',
    data
  })
}

//将指定考生添加Ai集中阅卷队列，全部添加
export function addAllExaminee (examId) {
  return request({
    url: `/ai/result/examinee/mark/add/all/examinee/${examId}`,
    method: 'post',
  })
}

//将指定考生添加Ai集中阅卷队列，全部添加
export function addExaminee (examId, data) {
  return request({
    url: `/ai/result/examinee/mark/add/examinee/${examId}`,
    method: 'post',
    data
  })
}


//获取考生Ai阅卷结果
export function getAiMarkingOutcome (examineeId) {
  return request({
    url: `/ai/result/examinee/mark/get/examinee/${examineeId}`,
    method: 'post',
  })
}

//获取考生答题数据-根据考生Id
export function getAiMarkingExamineeData (examineeId) {
  return request({
    url: `/exam/examinee/answer/record/get/examinee/${examineeId}`,
    method: 'post',
  })
}